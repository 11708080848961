import { send } from '@emailjs/browser';
import React, { useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import ContactForm from './contactform';

function Send(props) {
  // State to control modal visibility

  // Function to toggle the modal

  // Function to close the modal when clicking outside of the modal content
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modalOverlay') {
      props.setIsModalOpen(false);
    }
  };

  return (
    <div className="flex justify-center items-center first-letter:cursor-pointer" >
      {/* Icon */}
      <i className=" text-7xl md:text-[162px] text-black " >
        <BiPaperPlane />
      </i>

      {/* Modal */}
      {props.isModalOpen && (
        <div
          id="modalOverlay"
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOutsideClick}
        >
          <div className="bg-slate-100  md:pr-8 md:py-8 py-4 rounded-lg shadow-lg md:w-9/12 w-full mx-3" onClick={(e) => e.stopPropagation()}>
           <ContactForm/>
           
          </div>
        </div>
      )}
    </div>
  );
}

export default Send;
