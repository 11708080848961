import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import IndustryBar from "./IndustryBar";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
const mockItems = [
  { id: 0, img: "Medical Education.png", title: "Medical Education" },  
  { id: 1, img: "Designer.png", title: "Designer" },
  { id: 2, img: "Defence.png", title: "Defence" },
  { id: 3, img: "Real Estate.png", title: "Real Estate" },
  { id: 4, img: "Manufacturing.png", title: "Manufacturing" },
  { id: 5, img: "tourism.jpg", title: "Tourism" },
  { id: 6, img: "Healthcare.png", title: "Healthcare" },
];

function CenterMode() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleMenuClick = (slideIndex) => {
    setCurrentSlide(slideIndex);
    sliderRef.current.slickGoTo(slideIndex);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % mockItems.length;
    setCurrentSlide(nextSlide);
    sliderRef.current.slickGoTo(nextSlide);
  };

  const handlePrev = () => {
    const prevSlide = (currentSlide - 1 + mockItems.length) % mockItems.length;
    setCurrentSlide(prevSlide);
    sliderRef.current.slickGoTo(prevSlide);
  };

  const settings = {
    className: "center",
    centerMode: true,
    arrows: false,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 1,
    speed: 1000,
    adaptiveHeight: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: "190px",
        },
      },
    ],
  };

  return (
    <div className="w-full md:h-[950px] relative mb-2">
      <div className="slider-container w-full">
        <div className='rounded-xl w-11/12 mx-auto'>
          <IndustryBar currentSlide={currentSlide} handleMenuClick={handleMenuClick} />       
        </div>
        
        <button
          onClick={handlePrev}
          className="absolute z-20 top-[85%] -translate-y-1/2 -translate-x-full left-[73px] rounded-full xl:absolute xl:z-20 xl:top-1/2 xl:-translate-y-1/2 xl:-translate-x-full xl:left-72 xl:rounded-full border-white bg-white text-black px-2 py-2 flex items-center md:left-8"
        >
          <i className=" text-xl text-black">
        <FaArrowLeft/>
       </i>
        </button>

        <Slider ref={sliderRef} {...settings}>
          {mockItems.map((item) => (
            <div key={item.id} className="relative group">
              <div className="md:text-white rounded-2xl px-5 md:px-1 text-center">
                <img 
                  src={ item.img} 
                  alt={item.title} 
                  className="mx-auto aspect-video flex justify-center items-center w-full md:w-[98%] md:h-[720px] rounded " 
                />
                {/* Overlay on hover */}
                {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl">
                  <h3 className="text-white text-lg md:text-2xl font-semibold">{item.title}</h3>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>

        <button
          onClick={handleNext}
          className="absolute z-20 top-[85%] -translate-y-1/2 right-[70px] translate-x-full rounded-full border xl:absolute xl:z-20 xl:top-1/2 xl:-translate-y-1/2 xl:-translate-x-full xl:right-52 xl:rounded-full bg-white flex justify-center py-2 items-center px-2 border-white text-black md:right-8"
        >
            <i className=" text-b text-xl">
      <FaArrowRight/>
     </i>
        </button>
      </div>
    </div>
  );
}

export default CenterMode;
