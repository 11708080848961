import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Clients from '../Clients';
import Map from '../map';

const Commitment = () => {
  return ( 
    <div id="Commitment" className='bg-white mx-auto mt-64 md:mt-64 scroll-smooth'>
      <div className='w-11/12 mx-auto  items-start ml-5 xl:ml-28 py-4 '>
        
        <div className="flex  w-56 items-center whitespace-nowrap text-base font-semibold gap-2 py-2 px-3 justify-center  bg-gray-100 font-[Montserrat]  rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR COMMITMENT
            </div>
        <h1 className='flex  text-xl md:text-3xl pt-4 font-lexend text-black/80 font-medium'>
          Collaborated with Industry Leaders
        </h1>
      </div>
      <div >
        <Clients/>
      </div>
      <div>
        <Map/>
      </div>
    </div>
  );
}

export default Commitment;
