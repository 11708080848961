import React from "react";
import Boxnavbar from "./component/Nav/boxnavbar";
import Home from "./component/content/pages/Home";
import Contact from "./component/content/Findus/Contact";
import Interact from "./component/content/skid/Interact";
import Commitment from "./component/content/skid/commitment";
import About from "./component/content/Experties/About";
import Footer from "./component/content/Findus/footer";
import Bintogrid from "./component/content/Experties/Bintogrid";
import "@fontsource/lexend/500.css";
import Scroll from "./component/content/Findus/scroll";
import { useState, useEffect, useRef } from 'react';
// import useStore from "./component/hook/PermissionHome";
const FlyInSection = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.2 }
    );
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={ref}
      className={`transition-all duration-1000 transform ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-16 opacity-0'
      }`}
    >
      {children}
    </div>
  );
};
const App =  ({ children })  => {
// const {isOpen} = useStore()
  return (
    <div className="bg-black font-[Lexend] scroll-smooth ">
      <div className="flex flex-col">
        <div className="flex-1  ">
          <section id="Home" className="h-screen overflow-hidden">
            <h1>
              <Home />
            </h1>
          </section>
          
          <div className="flex-none  xl:sticky xl:top-0 xl:z-30 sticky top-0 z-30  ">
            <Boxnavbar />
          </div>
          <div className="">
            <section id="About">
              <h1>
                <About />
              </h1>
            </section>
            <Bintogrid/>
            <section id="Interact">
              <h1>
                <Interact />
              </h1>
            </section>
            <Commitment />
            <section id="Contact"  >
              <h1 className="bg-white " >
                
                <Contact />
              </h1>
              <div className="rounded-b-[60px] ">
                <Footer/>
              </div>
              <FlyInSection className="bg-black">
                  <Scroll/>
                </FlyInSection>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;


