import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Carouselservices from './services';
import Carouselindus from './Carouselindus';

const About = () => {
    return (
    <div id="About" className="flex flex-col scroll-smooth overflow-hidden space-y-2 ">
      <div className="bg-white flex flex-col space-y-2 pt-4 ">
          <div className="flex ml-5 xl:ml-28 w-56 items-center whitespace-nowrap text-base font-semibold gap-2 py-2 px-3 justify-center  bg-gray-100 font-[Montserrat]  rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR EXPERTISE
            </div>
          <h1 className="ml-5 xl:ml-28 text-2xl whitespace-nowrap  font-semibold md:whitespace-nowrap  xl:whitespace-nowrap  text-black">
              Explore our curated portfolio
          </h1>
     </div>
      <div className="">

        <Carouselservices />
      </div>
      <div className="">
        <Carouselindus />
      </div>
    </div>
  );
};

export default About;
