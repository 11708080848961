import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Lottie from "lottie-react";
import map_lottie from './skid/beebox-map.json';
import Gradient from './skid/gradient.json';

const Map = () => {
  return (


    <div className="relative w-[87.5%]  mx-auto mt-20 bg-black overflow-hidden rounded-2xl">
    <div className='absolute -top-60 left-0 w-1/2 h-1/2 md:w-1/3 md:h-1/3 xl:w-1/4 xl:h-1/4 pointer-events-none backdrop-blur-[89.3px] opacity-90 filter blur-3xl bg-black/20'>
          <Lottie animationData={Gradient} loop={true} className='w-[1700px]'/>
        </div>

        <Lottie animationData={map_lottie} loop={true} className="h-[450px] max-w-full " />
        {/* Our Footprint Button */}
        <div className='absolute top-4'>
          <div className="flex ml-5 w-56 items-center whitespace-nowrap text-base font-semibold gap-2 py-2 px-3 justify-center  bg-gray-100 font-[Montserrat]  rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR FOOTPRINT
            </div>
        </div>
        
        <div className='absolute bottom-4 right-4'>
          <a
            href="#Contact"
            className="flex items-center hover:bg-white hover:text-black font-lexend font-semibold text-white border border-white py-2 px-6 gap-2 rounded-3xl inline-flex"
          >
            <span>Let's Partner</span>
            <svg
              className="w-4"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
            </svg>
          </a>
        </div>
</div>

  );  
}

export default Map;
