// import React from 'react'

// const Scroll = () => {
//   return (
//     <div className=' h-screen bg-black flex-col'>
//   <div className=' flex justify-center items-center  mt-10'>

//  <h1 className=' font-[Montserrat] font-extrabold text-6xl text-customOrange'>
//   Making every buzz count

//  </h1>
//   </div>
//         <div className=' font-[Lexend] w-full flex'>
//             <div className='w-1/3'>
//             <div className=" text-white ">&copy; 2024 All Rights Reserved, <span>BeeBox Studios Private Limited</span>.</div>
//             </div>

//             <div className='w-1/3 text-white '>
//              Made with from chennai
//              </div>

//             <div className=' w-1/3 text-white '>
//               <a href="https://www.beeboxstudios.com/privacy-policy" className="text-sm underline hover:text-blue-400">Privacy Policy</a>
//             </div>

//         </div>
//     </div>
//   )
// }

// export default Scroll

import React from "react";
import BeeSpline from "./BeeSpline";

const FooterSection = () => {
  return (
    <footer className="py-16">
      <div className=" flex justify-center items-center  ">
        <h1 className=" font-[Montserrat] font-extrabold text-5xl text-customOrange">
          Making every buzz count
        </h1>
      </div>
      <BeeSpline />
      {/* Footer Links */}
      <div className="flex justify-between    text-white  w-full items-center text-sm px-16 ">
        <div className=" flex  justify-starttext-sm md:text-xl whitespace-nowrap">
          <p className="whitespace-nowrap text-white ">
            © 2024 Copyright,
            <br /> Beebox Studios Private Limited.
          </p>
        </div>

        <div className="flex   ">
          <p className=" text-sm md:text-xl text-white  whitespace-nowrap gap-1">
            Made with <span className="text-yellow-400">💛</span> from Chennai
            <span role="img" aria-label="India Flag">
              {" "}
              🇮🇳
            </span>
          </p>
        </div>

        <div className="flex  text-white  text-sm md:text-xl ">
          <a
            href="https://www.beeboxstudios.com/privacy-policy"
            className=" hover:text-sky-900 underline pb-5"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
