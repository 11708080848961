import React, { useState, useEffect } from "react";
import { HiMenu, HiX } from "react-icons/hi";
import { Burgermenu } from "./burgermenu";

const sections = [
  { id: "Home", label: "HOME" },
  { id: "About", label: "ABOUT" },
  { id: "Interact", label: "INTERACT" },
  { id: 'Contact', label: 'CONTACT  ' },

];

const Navbar = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = '';

      sections.forEach(section => {
        document.body.style.overflow ="auto"
        const element = document.getElementById(section.id);
        const rect = element.getBoundingClientRect();

        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          currentSection = section.id;
        }
      });

      setSelectedItem(currentSection);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".mobile-menu")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={` left-0 w-full  transition-all duration-300 sticky top-10 z-30 ${
        isScrolled ? "bg-white/50 backdrop-blur-3xl shadow-lg  w-11/12 mx-auto" : "bg-slate-100"
      } `}
    >
      <div className="flex items-center justify-between xl:mx-28 mx-5 py-3">
        <div className="md:w-1/3">
          <div className="flex items-center gap-1 lg:gap-3">
            <img
              src="/logo1.png"
              className="lg:w-12 lg:h-12 w-8 h-8"
              alt="Logo"
            />
            <div className="text-black/90 md:text-xl lg:text-xl xl:text-2xl text-lg font-montserrat font-bold">
              <span>Beebox</span> <span>Studios</span>
            </div>
          </div>
        </div>
        <div className="xl:hidden block">
          <button
            className="text-2xl text-black font-bold"
            onClick={toggleMenu}
          >
            {isOpen ? <HiX /> : <HiMenu />}
            {/* <Burgermenu/> */}
          </button>
          <div
            className={`fixed top-0 left-0 h-screen w-1/2 bg-slate-100 ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } z-50`}
            style={{ transition: "transform 300ms ease-in-out" }}
          >
            <div className="flex justify-center my-5">
              <img
                src="/logo1.png"
                className="w-12 h-12"
                alt="Logo"
              />
            </div>


            {/* mobile menu */}
            <nav className="mobile-menu flex flex-col items-center h-full ">
              <ul className="space-y-4">
                {sections.map((section) => (
                  <li key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className={`block text-slate-900 px-5 py-2 font-semibold rounded-2xl  ${
                        selectedItem === section.id
                          ? "border border-slate-700"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedItem(section.id);
                        setIsOpen(false);
                      }}
                    >
                      {section.label}
                    </a>
                  </li>
                ))}
             
              </ul>
            </nav>
          </div>
        </div>

        <nav className="hidden xl:flex items-center">
          <ul className="flex space-x-4 font-semibold font-montserrat  text-sm md:text-lg">
            {sections.map((section) => (
              <li key={section.id}>
                <a
                  href={`#${section.id}`}
                  className={`text-black  px-5 py-3 font-semibold rounded-xl hover:border border-zinc-900 ${
                    selectedItem === section.id ? "bg-customOrange " : "text-black"
                  }`}
                  onClick={() => {
                    setSelectedItem(section.id);
                  }}
                >
                  {section.label}
                </a>
              </li>
            ))}
           
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
