import React, { useState } from "react";
import axios from "axios";
import { TbMailUp } from "react-icons/tb";
import Whatsapp from "./whatsapp";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    topic: "",
    name: "",
    organization: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(true);
  const [charCount, setCharCount] = useState(0);
  const [topic, setTopic] = useState("");

  const categories = [
    "Project Inquiry",
    "Job or Internship",
    "Consultation",
    "Event Engagements",
    "Media and PR",
    "Partnership",
    "Others",
  ];

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.message.trim()) {
      formErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "message") {
      setCharCount(value.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const res = await axios.postForm(
        "https://www.beeboxstudios.com/contactinfo",
        formData
      );
      setIsSent(false);
      setFormData({
        name: "",
        organization: "",
        email: "",
        phone: "",
        message: "",
      });
      console.log(res.data);

      setCharCount(0);

      setTimeout(() => {
        setIsSent(true);
      }, 5000);
    } catch (error) {
      console.error("Error submitting the form", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  console.log(topic);

  return (
    <div className="flex flex-col md:h-full md:flex-row justify-center items-center rounded-lg w-full md:mx-auto overflow-x-hidden overflow-y-auto h-[600px]">
      {/* WhatsApp QR Section */}
      <div className="hidden md:flex md:justify-center">
        <Whatsapp />
      </div>

      {/* Form Section */}
      <div className="md:flex-1  flex-col full md:border-l-2 px-4 md:border-slate-300 md:pl-8 md:w-3/4  ">
      <div className=" flex  items-center justify-center text-center">

      <span className="hidden md:block md:text-5xl text-xl  font-bold mb-4 text-center">
      or,
      </span>
        <h2 className="md:text-5xl text-xl  font-bold mb-4 text-center">
         Let’s hear you out!
        </h2>
      </div>

        <div className="flex flex-wrap gap-2 justify-center mb-6">
          {categories.map((category, index) => (
            <button
              key={index}
              onClick={() => setTopic(category)}
              className={`md:px-4 md:py-2 px-2 py-1  md:text-sm text-xs border rounded-full ${
                topic === category
                  ? "bg-black text-white"
                  : "bg-white text-black border-black"
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {isSent ? (
          <form
            action="https://www.beeboxstudios.com/contactinfo"
            method="post"
            onSubmit={handleSubmit}
            className="flex flex-col gap-4"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-">
              <div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your name*"
                  autoComplete="off"
                  className="input w-full transition-all md:text-sm text-xs duration-300 ease-in-out md:p-4 p-2 rounded-lg bg-gray-300 shadow-inner focus:bg-white focus:scale-105 focus:shadow-[13px_13px_100px_#969696,-13px_-13px_100px_#ffffff] outline-none"
                />
                {errors.name && (
                  <p className="text-red-500  md:text-sm text-xs ">
                    {errors.name}
                  </p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  placeholder="Your organization*"
                  autoComplete="off"
                  className="input transition-all  w-full  md:text-sm text-xs duration-300 ease-in-out md:p-4 p-2 rounded-lg bg-gray-300 shadow-inner focus:bg-white focus:scale-105 focus:shadow-[13px_13px_100px_#969696,-13px_-13px_100px_#ffffff] outline-none"
                />
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email address*"
                  autoComplete="off"
                  className="input transition-all w-full  md:text-sm text-xs duration-300 ease-in-out md:p-4 p-2 rounded-lg bg-gray-300 shadow-inner focus:bg-white focus:scale-105 focus:shadow-[13px_13px_100px_#969696,-13px_-13px_100px_#ffffff] outline-none"
                />
                {errors.email && (
                  <p className="text-red-500  md:text-sm text-xs">
                    {errors.email}
                  </p>
                )}
              </div>
              <div>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  autoComplete="off"
                  className="input transition-all w-full  md:text-sm text-xs duration-300 ease-in-out md:p-4 p-2 rounded-lg bg-gray-300 shadow-inner focus:bg-white focus:scale-105 focus:shadow-[13px_13px_100px_#969696,-13px_-13px_100px_#ffffff] outline-none"
                  placeholder="Phonenumber"
                />
              </div>
            </div>
            <div className="relative w-full">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                placeholder="Your message for us*"
                autoComplete="off"
                maxLength="5000"
                className="w-full transition-all  md:text-sm text-xs duration-300 ease-in-out md:p-4 p-2 rounded-lg bg-gray-300 shadow-inner focus:bg-white focus:scale-105 focus:shadow-[13px_13px_100px_#969696,-13px_-13px_100px_#ffffff] outline-none resize-none"
              ></textarea>

              <span className="absolute bottom-2 right-3 text-gray-500 text-sm">
                {charCount}/5000
              </span>
            </div>
            {errors.message && (
              <p className="text-red-500 md:text-sm text-xs ">
                {errors.message}
              </p>
            )}
            <button
              type="submit"
              className={`w-full gap-2 bg-orange-500 text-white  md:text-sm text-sm font-medium py-3 rounded-md flex items-center justify-center ${
                isSubmitting ? "cursor-not-allowed bg-amber-300" : ""
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send mail"}
              <i className="text-white text-2xl font-extrabold">
                <TbMailUp />
              </i>
            </button>
          </form>
        ) : (
          <div className="text-center p-6 bg-green-100 rounded-lg">
            <h3 className="text-2xl font-semibold text-green-800">
              Thank you! Your message has been sent successfully.
            </h3>
            <p className="text-gray-600 mt-2">
              We will get back to you as soon as possible.
            </p>
          </div>
        )}
      </div>
      <div className="block md:hidden w-full px-4 border-t mt-5 mx-5 border-slate-300  md:text-sm text-sm font-medium">
        <button className=" bg-green-500  mt-5 w-full  text-white  py-3 gap-2   rounded-md flex items-center justify-center">
          Or, reach out to us on
          <a
            href="https://api.whatsapp.com/send/?phone=918754483451&amp;text=Hello+Beebox,+I+would+like+to+get+more+information&amp;type=phone_number&amp;app_absent=0"
            title="Whatsapp"
            className="flex   items-center gap-2 text-white text-sm md:text-base"
          >
            <i className="fab fa-whatsapp text-2xl text-center"></i>
          </a>
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
