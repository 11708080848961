import React from "react";
import { PiStarFourFill } from "react-icons/pi";
import Skid from "../pages/Skid Steer.mp4";

const Interact = () => {
  const routeChange = () => {
    alert("Button clicked");
    window.location.href = `/Operate`;
  };

  return (
    <div id="Interact" className="h-screen md:pt-8 scroll-smooth">
      <div className="relative">
        <video
          className="absolute inset-0 w-full h-screen object-cover mt-56"
          autoPlay
          loop
          muted
        >
          <source src={Skid} type="video/mp4" className="xl:h-screen" />
        </video>
        <div className="absolute"></div>
        <div className="relative z-10 h-full flex flex-col justify-start items-start text-start ml-5 xl:ml-28 ">
          <div className="flex  w-56 items-center whitespace-nowrap text-base font-semibold gap-2 py-2 px-3 justify-center  bg-gray-100 font-[Montserrat]  rounded-3xl shadow">
            <i className="text-lg text-amber-400">
              <PiStarFourFill />
            </i>
            OUR CREATION
          </div>

          <h1 className="flex my-2 text-2xl sm:text-4xl font-semibold mt-2 text-white font-montserrat">
            Interactive 3D
            <br />
            Machinery Tutorial
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Interact;
