import React from 'react';
import { FcFactoryBreakdown } from "react-icons/fc";
const ServiceBar = ({ currentSlide, handleMenuClick }) => {
  const menuItems = [
    { id: 0, label: 'Education' },  
    { id: 1, label: "Energy" },
    { id: 2, label: "Defence"},
    { id: 3, label: "Real Estate" },
    { id: 4, label:  "Manufacturing"},
    { id: 5, label: "Tourism" },
    { id: 6, label: "Healthcare", },
  ];
  

  return (
    <nav className="   ">
      <div className="w-full md:11/12 mx-auto flex flex-wrap justify-center gap-4 items-center">
     


      <div className="xl:flex gap-2 items-center whitespace-nowrap p-3 justify-center flex bg-gray-100 font-montserrat xl:text-sm text-xs font-semibold  rounded-3xl shadow">
      <i className="text-xl">
          <FcFactoryBreakdown />
        </i>  
        Industry Verticals
        </div>
      <div className="w-full flex justify-center items-center pb-4">
             <div className="text-sm flex flex-wrap justify-center items-center p-4 uppercase border border-white/10 bg-white/10 shadow-md rounded-3xl transition-colors backdrop-blur-sm">
          {menuItems.map((item) => (
            <a
              key={item.id}
              href={item.href}
              onClick={() => handleMenuClick(item.id)}
              className={`block mt-6 lg:inline-block lg:mt-0 text-white 
                            text-xs md:text-sm 2xl:text-lg font-light   py-2 px-12 rounded-full mr-2 font-lexend ${
                               currentSlide === item.id
                                 ? "bg-orange-500 text-white"
                                 : "hover-border"
                            }`}
                          style={{ minWidth: "8rem" }}
            >
              {item.label}
            </a>
          ))}
        </div>
      </div>
      </div>
    </nav>
  );
};

export default ServiceBar;
