import React from 'react';

const ServiceBar = ({ currentSlide, handleMenuClick }) => {
  const menuItems = [
    { id: 0, label: 'Sales & Marketing', href: '#home' },
    { id: 1, label: 'Industrial Training', href: '#about' },
    { id: 2, label: 'Operations & Maintenance', href: '#services' },
    { id: 3, label: 'After Sales Support', href: '#portfolio' },
  ];

  return (
    <nav className=" pt-4 ">
        <div className=" flex flex-wrap gap-1  w-4/5 mx-auto justify-center items-center uppercase p-4  border-white/10 bg-white/10  shadow-md rounded-3xl transition-colors backdrop-blur-sm">
          {menuItems.map((item) => (
            <a
              key={item.id}
              href={item.href}
              onClick={() => handleMenuClick(item.id)}
              className={`block text-white items-center text-xs md:text-sm  2xl:text-lg py-2 font-light px-12 font-lexend rounded-3xl ${
                currentSlide === item.id ? 'bg-orange-500 text-white' : 'hover-border'
              }`}
            >
              {item.label}
            </a>
          ))}
        </div>
                      
                      
    </nav>
  );
};

export default ServiceBar;
