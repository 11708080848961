// import Spline from '@splinetool/react-spline';

// export default function App() {
//   return (
//     <Spline scene="https://prod.spline.design/cYtblTmTnk2R9qLl/scene.splinecode" />
//   );
// }



import React from 'react';
import Spline from '@splinetool/react-spline';

const BeeSpline = () => (
  <div className=' flex justify-center p-10'>
    <div className='flex w-[900px] '>

    <Spline scene="https://prod.spline.design/cYtblTmTnk2R9qLl/scene.splinecode" />``
    </div>
  </div>
);

export default BeeSpline;
